import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface AuthProps {
  children?: ReactNode;
}

const Auth: React.FC<AuthProps> = ({ children }) => (
  <div className="main d-flex w-100 justify-content-center">
    <Container className="d-flex flex-column">
      <Row className="h-100">
        <Col sm="10" md="8" lg="6" xl="5" className="mx-auto d-table h-100">
          <div className="d-table-cell align-middle">{children}</div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Auth;
