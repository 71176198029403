import * as React from 'react';
import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { Button } from 'react-bootstrap';
import Link from 'next/link';
import getServerSideTranslations from '../utils/get-server-side-translations/get-server-side-translations';
import PageMeta from '../components/page-meta/page-meta.component';
import Auth from '../layouts/auth';

const Custom404: NextPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageMeta title={t('PAGE_404.META.TITLE')} />
      <div className="text-center">
        <h1 className="display-1 fw-bold">{t('PAGE_404.PAGE_TITLE')}</h1>
        <p className="h2">{t('PAGE_404.SUBTITLE')}</p>
        <p className="lead fw-normal mt-3 mb-4">{t('PAGE_404.DESCRIPTION')}</p>
        <Link href="/" passHref>
          <Button variant="primary" size="lg">
            {t('PAGE_404.BACK_BUTTON.TITLE')}
          </Button>
        </Link>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale)),
    },
  };
};

// @ts-ignore
Custom404.getLayout = (page: React.ReactElement) => <Auth>{page}</Auth>;

export default Custom404;
